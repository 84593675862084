import React, { useContext, useState } from 'react';
import { Menu, ChevronDown } from 'react-feather';
import AdminContext from '../../config/AdminContext';
import axios from '../../config/axios';

export default function Header() {

	let [isDropdownOpen, setIsDropdownOpen] = useState(false)
	let loggedInAdmin = useContext(AdminContext);

	let handleLogout = async () => {

		try{

			let res = await axios.post('/logout');

			if(res.status === 200){
				window.location.pathname = '/login'
			}

		}catch(err){
			alert('حدث خطأ ما ، يرجى التحديث وإعادة المحاولة')
		}

	}

	return (
		<React.Fragment>
			<header className="bg-gray-800">
				<div className="flex justify-between items-center container">
					<span></span>
					<div className="p-1 flex flex-row cursor-pointer">
						<button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="text-white p-2 no-underline flex items-center bg-gray-700 rounded-full focus:outline-none">
							<span className="font-semibold text-sm ml-1">
								{loggedInAdmin.admin.name}
							</span>
							<ChevronDown size={17} />
						</button>
						<div
							id="ProfileDropDown"
							className={`rounded ${!isDropdownOpen ? 'hidden' : ''} z-50 shadow-md bg-white absolute pin-t mt-12 mr-1 pin-r`}
						>
							<ul className="list-reset">
								<li>
									<a
										href="/admin/settings"
										className="no-underline px-4 py-2 block text-black hover:bg-gray-200"
									>
										الإعدادات
									</a>
								</li>
								<hr />
								<li>
									<button
										onClick={handleLogout}
										className="cursor-pointer no-underline px-4 py-2 block text-black hover:bg-gray-200"
									>
										تسجيل خروج
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</header>
		</React.Fragment>
	);
}
