import React, { useState, useEffect } from "react";
import axios from "../../config/axios";
import Loader from "./../common/Loader";
import UserTableRow from "./UserTableRow";
import { useFormik } from "formik";

export default function UsersTable({ is_new_users }) {
  let [data, setData] = useState({ users: [], total: 0 });
  let [isLoadingUsers, setIsLoadingUsers] = useState(true);
  let [offset, setOffset] = useState(0);
  let [noMoreNext, setNoMoreNext] = useState(true);
  let [noMorePrev, setNoMorePrev] = useState(true);
  let [search, setSearch] = useState("");

  let limit = 10;

  useEffect(() => {
    (async function () {
      if (!isLoadingUsers) {
        setIsLoadingUsers(true);
      }
      try {
        let res = await axios().get(
          `${!is_new_users ? "/users" : "/new-users"}`,
          {
            params: { offset, limit, search },
          }
        );

        let totalNewUsers = 0;
        // if (is_new_users) {
        //   let homeData = await axios.get("/");
        //   totalNewUsers = homeData.data.new_users;
        // }

        if (res.status === 200) {
          setData({
            users: res.data.users,
            total: !is_new_users ? res.data.totalUsers : totalNewUsers,
          });

          // check for next & prev btns
          if (offset === 0 && res.data.users.length < limit) {
            setNoMoreNext(true);
            setNoMorePrev(true);
          } else if (offset > 0 && res.data.users.length < limit) {
            setNoMoreNext(true);
            setNoMorePrev(false);
          } else if (offset > 0 && res.data.users.length >= limit) {
            setNoMoreNext(false);
            setNoMorePrev(false);
          } else {
            setNoMoreNext(false);
            setNoMorePrev(true);
          }
        }
      } catch (err) {
        // console.log(err)
        alert("Something went wrong");
      }

      setIsLoadingUsers(false);
    })();
  }, [offset, search]);

  let nextUsers = () => {
    if (!noMoreNext) {
      setOffset((prevOffset) => {
        return prevOffset + limit;
      });
    }
  };

  let prevUsers = () => {
    if (!noMorePrev) {
      setOffset((prevOffset) => {
        return prevOffset - limit;
      });
    }
  };

  let formik = useFormik({
    initialValues: {
      query: "",
    },

    onSubmit: ({ query }) => {
      setSearch(query);
    },
  });
  return (
    <React.Fragment>
      <div class="flex flex-col h-full">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          {!is_new_users ? (
            <form onSubmit={formik.handleSubmit}>
              <div class="relative rounded-md shadow-sm flex">
                <input
                  class="form-input block w-full p-2 rounded sm:text-sm sm:leading-5"
                  placeholder="ابحث عن مستخدم"
                  name="query"
                  onChange={formik.handleChange}
                />
                <button className="shadow bg-blue-400 hover:bg-blue-500 rounded text-white text-bold px-6">
                  بحث
                </button>
              </div>
            </form>
          ) : null}
          <div class="align-middle inline-block min-w-full h-screen sm:rounded-lg border-b border-gray-200">
            <table class="min-w-full shadow">
              <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    الاسم الكامل
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    البريد الإلكتروني
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    مجمل النقاط
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    تأكيد الحساب
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    طريقة التسجيل
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    انضم في
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50" />
                </tr>
              </thead>
              <tbody class="bg-white">
                {isLoadingUsers ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="py-3">
                      <Loader className="mx-auto py-5" />
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : !isLoadingUsers && data.users.length === 0 ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="py-3"> لم يتم العثور على مستخدمين </td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  data.users.map((user) => <UserTableRow user={user} />)
                )}
              </tbody>
            </table>
            {/* +++++++ Next & Prev +++++++ */}
            <div class="bg-white px-4 py-3 flex shadow items-center justify-between border-t border-gray-200 sm:px-6">
              <div class="flex-1 flex items-center justify-between">
                <div>
                  <p class="text-sm leading-5 text-gray-700">
                    عرض
                    <span class="font-medium px-1">{offset}</span>
                    إلى
                    <span class="font-medium px-1">
                      {offset + (data.users.length || limit)}
                    </span>
                    من أصل
                    <span class="font-medium px-1">
                      {isLoadingUsers ? "..." : data.total}
                    </span>
                    نتيجة
                  </p>
                </div>
                <div>
                  <nav class="relative z-0 inline-flex shadow-sm">
                    <button
                      onClick={nextUsers}
                      type="button"
                      class={`-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 ${
                        noMoreNext ? "cursor-not-allowed" : ""
                      }`}
                      aria-label="Next"
                    >
                      <svg
                        class="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>

                    <button
                      onClick={prevUsers}
                      type="button"
                      class={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 ${
                        noMorePrev ? "cursor-not-allowed" : ""
                      }`}
                      aria-label="Previous"
                    >
                      <svg
                        class="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
            {/* +++++++ End: Next & Prev +++++++ */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
