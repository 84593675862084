import React, { useState } from "react";
import timeAgo from "nta";
import Tip from "../tip";
import {
  Trash,
  CheckCircle,
  Award,
  MoreHorizontal,
  UserCheck,
} from "react-feather";
import DeleteUserModal from "./DeleteUserModal";
import GiveSpecialBadgeModal from "./GiveSpecialBadgeModal";
import ConfirmUserAccountModal from "./ConfirmUserAccountModal";
import UserActionsDropdown from "../dropdowns/UserActionsDropdown";
import GiveUserPointsModal from "../modals/GiveUserPointsModal";
import GiveManagerBadgeModal from "../modals/GiveManagerBadgeModal";
// import EditTopicModal from './EditTopicModal';
// import DeleteTopicModal from './DeleteTopicModal';

export default function UserTableRow({ user }) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSpecialBadgeOpen, setIsSpecialBadgeOpen] = useState(false);
  const [isConfirmAccountOpen, setIsConfirmAccountOpen] = useState(false);
  const [isGivePointsModalOpen, setIsGivePointsModalOpen] = useState(false);
  const [
    isGiveManagerBadgeModalOpen,
    setIsGiveManagerBadgeModalOpen,
  ] = useState(false);
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);
  

  return (
    <tr className="text-sm">
      <td class="px-6 py-4 whitespace-nowrap border-b border-gray-200">
        <p className="flex items-center">
          {user.fullname}
          {!user.manager_badge ? null : (
            <Tip content={user.manager_badge === "mod" ? 'Community Mod' : 'Community Manager'}>
              <span className="bg-gray-100 mr-1 inline-block p-1 text-gray-600 rounded-full">
                <UserCheck size={14} />
              </span>
            </Tip>
          )}
        </p>
      </td>
      <td class="px-6 py-4 border-b border-gray-200">{user.email}</td>
      <td class="px-6 py-4 border-b border-gray-200">{user.points}</td>
      <td class="px-6 py-4 whitespace-nowrap border-b border-gray-200">
        {user.verified === 1 ? (
          <span className="bg-green-100 text-green-500 rounded-full py-1 px-2 text-sm">
            تم تأكيد
          </span>
        ) : (
          <span className="bg-red-100 text-red-500 rounded-full py-1 px-2 text-sm">
            غير مؤكد
          </span>
        )}
      </td>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        {user.method === "local" || user.method === ""
          ? "البريد الإلكتروني"
          : user.method}
      </td>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
        {timeAgo(user.created_at)}
      </td>

      <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
        <div className="relative max-w-md">
          <span
            onClick={() => setIsActionDropdownOpen(!isActionDropdownOpen)}
            className={`
              inline-block cursor-pointer transition hover:bg-gray-200 hover:text-black rounded-full p-1
              ${
                isActionDropdownOpen
                  ? "bg-blue-100 text-blue-500"
                  : "bg-gray-100 text-gray-500"
              }
            `}
          >
            <MoreHorizontal size={19} />
          </span>
          {isActionDropdownOpen && (
            <UserActionsDropdown
              onClickDeleteUser={() => setIsDeleteModalOpen(true)}
              onClickGiveSpecialBadge={() => setIsSpecialBadgeOpen(true)}
              onClickConfirmAccount={() => setIsConfirmAccountOpen(true)}
              onClickAddPoints={() => setIsGivePointsModalOpen(true)}
              onClickAddManagerBadge={() =>
                setIsGiveManagerBadgeModalOpen(true)
              }
              isUserVerified={!!user.verified}
            />
          )}
        </div>

        {isGiveManagerBadgeModalOpen && (
          <GiveManagerBadgeModal
            userId={user.id}
            userBadge={user.manager_badge}
            closeModal={() => setIsGiveManagerBadgeModalOpen(false)}
          />
        )}
        {isGivePointsModalOpen && (
          <GiveUserPointsModal
            userId={user.id}
            closeModal={() => setIsGivePointsModalOpen(false)}
          />
        )}
        {/* Modals */}
        {isDeleteModalOpen && (
          <DeleteUserModal
            user={user}
            isOpen={isDeleteModalOpen}
            closeModal={() => setIsDeleteModalOpen(false)}
          />
        )}

        {isSpecialBadgeOpen && (
          <GiveSpecialBadgeModal
            user={user}
            isOpen={isSpecialBadgeOpen}
            closeModal={() => setIsSpecialBadgeOpen(false)}
          />
        )}

        {isConfirmAccountOpen && (
          <ConfirmUserAccountModal
            user={user}
            isOpen={isConfirmAccountOpen}
            closeModal={() => setIsConfirmAccountOpen(false)}
          />
        )}
      </td>
    </tr>
  );
}
