import React from "react";
import CreateTopicForm from "../components/topics/CreateTopicForm";
import TopicsTable from "./../components/topics/TopicsTable";
import Cookies from "js-cookie";
import SuccessMsg from "./../components/common/alerts/Success";

export default function Topics() {
  return (
    <React.Fragment>
      <div class="container mx-auto">
        <TopicsTable />
      </div>
    </React.Fragment>
  );
}
