import React, {useState, useEffect} from 'react';
import PasswordTab from '../components/admin-settings/PasswordTab';
import EmailTab from '../components/admin-settings/EmailTab';
import Cookies from 'js-cookie';
import SuccessMsg from './../components/common/alerts/Success';
import NameTab from '../components/admin-settings/NameTab';

export default function AdminSettings() {

   let [isOpen, setIsOpen] = useState({
      email: true,
		password: false,
		name: false
	});
	

	return (
		<div className="bs-container mt-40 md:mt-32">
			<div className="tabs-wrapper">
				{
					Cookies.get('success') ? (
						<SuccessMsg 
							body={Cookies.get('success')} 
						/>
					) : null
				}
				<div className="block md:flex">
					<div className="w-full md:w-3/12 border-b md:border-l border-gray-300">
						<div className="shadow bg-white flex justify-between md:block">
							<span
                        data-tab="email_tab"
                        onClick={() => setIsOpen({email: true, name: false, password: false})}
                        className={`block tab-btn cursor-pointer py-4 px-3 w-full md:text-right text-center ${
                           isOpen.email ? 'bg-blue-400 text-white font-semibold' : ''
                        }`}
							>
								البريد الإلكتروني
							</span>
							<span
								data-tab="name_tab"
								onClick={() => setIsOpen({ email: false, name: true, password: false })}
								className={`block tab-btn cursor-pointer py-4 px-3 w-full md:text-right text-center ${
									isOpen.name ? 'bg-blue-400 text-white font-semibold' : ''
									}`}
							>
							 الاسم 
							</span>
							<span
                        data-tab="password_tab"
								onClick={() => setIsOpen({ email: false, name: false, password: true })}
                        className={`block tab-btn cursor-pointer py-4 px-3 w-full md:text-right text-center ${
                           isOpen.password ? 'bg-blue-400 text-white font-semibold' : ''
                        }`}
							>
								كلمة المرور
							</span>
							
						</div>
					</div>
					<div className="w-full md:w-9/12 p-2">
						{/* + Email Tab */}
                  <EmailTab isOpen={isOpen.email} />
                  {/* - Email Tab */}
						
                  {/* + Password tab */}
                  <PasswordTab isOpen={isOpen.password} />
						{/* - Password tab */}
						
                  {/* + name tab */}
                  <NameTab isOpen={isOpen.name} />
						{/* - name tab */}
						

					</div>
				</div>
			</div>
		</div>
	);
}
