import React, { useState, useEffect } from "react";
import axios from "../../config/axios";
import Loader from "../common/Loader";
import ReportTableRow from "./ReportTableRow";

export default function ReportsTable() {
  let [data, setData] = useState({ reports: [], total: 0 });
  let [isLoadingReports, setIsLoadingReports] = useState(true);
  let [offset, setOffset] = useState(0);
  let [noMoreNext, setNoMoreNext] = useState(false);
  let [noMorePrev, setNoMorePrev] = useState(true);
  let limit = 5;

  useEffect(() => {
    (async function () {
      try {
        let res = await axios().get("/reports", {
          params: { offset, limit },
        });

        // console.log(res.data);

        if (res.status === 200) {
          setData({
            reports: res.data.reports,
            total: res.data.totalReports,
          });

          // check for next & prev btns
          if (offset === 0 && res.data.reports.length < limit) {
            setNoMoreNext(true);
            setNoMorePrev(true);
          } else if (offset > 0 && res.data.reports.length < limit) {
            setNoMoreNext(true);
            setNoMorePrev(false);
          } else if (offset > 0 && res.data.reports.length >= limit) {
            setNoMoreNext(false);
            setNoMorePrev(false);
          } else {
            setNoMoreNext(false);
            setNoMorePrev(true);
          }
        }
      } catch (err) {
        alert("Something went wrong");
      }

      setIsLoadingReports(false);
    })();
  }, [offset]);

  let nextReports = () => {
    if (!noMoreNext) {
      setOffset((prevOffset) => {
        return prevOffset + limit;
      });
    }
  };

  let prevReports = () => {
    if (!noMorePrev) {
      setOffset((prevOffset) => {
        return prevOffset - limit;
      });
    }
  };

  return (
    <React.Fragment>
      <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    أبلغ من قبل
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    الإبلاغ عن
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    المحتوى
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    سبب الإبلاغ
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50" />
                </tr>
              </thead>
              <tbody class="bg-white">
                {isLoadingReports ? (
                  <tr>
                    <td />
                    <td className="py-3 text-center">
                      <Loader className="mx-auto py-5" />
                    </td>
                    <td />
                    <td />
                  </tr>
                ) : !isLoadingReports && data.reports.length === 0 ? (
                  <tr>
                    <td />
                    <td className="py-3 text-center">
                      لم يتم العثور على إبلاغات
                    </td>
                    <td />
                    <td />
                  </tr>
                ) : (
                  data.reports.map((report, i) => (
                    <ReportTableRow
                      key={i}
                      reportId={report.id}
                      answer={{
                        id: !report.reply ? null : report.reply.rid,
                        body: !report.reply ? null : report.reply.reply,
                      }}
                      comment={{
                        id: !report.answer_comment ? null : report.answer_comment.id,
                        body: !report.answer_comment ? null : report.answer_comment.body,
                      }}
                      question={{
                        id: !report.question ? null : report.question.id,
                        title: !report.question ? null : report.question.title,
                        slug: !report.question ? null : report.question.slug,
                      }}
                      reason={report.reason}
                      reportedBy={report.report_by}
                      reportedContent={
                        !!report.answer_id
                          ? "answer"
                          : !!report.comment_id
                          ? "comment"
                          : "question"
                      }
                    />
                  ))
                )}
              </tbody>
            </table>
            {/* +++++++ Next & Prev +++++++ */}
            <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
              <div class="flex-1 flex items-center justify-between">
                <div>
                  <p class="text-sm leading-5 text-gray-700">
                    عرض
                    <span class="font-medium px-1">{offset}</span>
                    إلى
                    <span class="font-medium px-1">
                      {offset + (data.reports.length || limit)}
                    </span>
                    من أصل
                    <span class="font-medium px-1">
                      {isLoadingReports ? "..." : data.total}
                    </span>
                    نتيجة
                  </p>
                </div>
                <div>
                  <nav class="relative z-0 inline-flex shadow-sm">
                    <button
                      onClick={nextReports}
                      type="button"
                      class={`-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 ${
                        noMoreNext ? "cursor-not-allowed" : ""
                      }`}
                      aria-label="Next"
                    >
                      <svg
                        class="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>

                    <button
                      onClick={prevReports}
                      type="button"
                      class={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 ${
                        noMorePrev ? "cursor-not-allowed" : ""
                      }`}
                      aria-label="Previous"
                    >
                      <svg
                        class="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
            {/* +++++++ End: Next & Prev +++++++ */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
